<script setup>
  import noCartList from './no-list'
  import { debounce } from '@/util/func'
  import { getCurrentInstance, onMounted, reactive, defineEmits, nextTick } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const { proxy } = getCurrentInstance()

  const data = reactive({
    loading: false,
    all: 1, // 是否可以全选
    productList: [], // 商品列表
    checkList: [], // 选择商品列表
    quantityNum: 0, // 总数量
    freightMoney: 0, // 勾选总运费
    totalMoney: 0, // 勾选总价格
    checkAll: false, //是否全选
    checkDeleteAll: false // 删除是否全选
  })

  //查询购物车列表
  const queryCartPro = () => {
    data.loading = true
    // 判断是否登录
    if (!userStore.userInfo.userId) {
      return $emit('initNoList')
    }

    api.product
      .getcartSpecList({
        'userId.equals': userStore.userInfo.userId
      })
      .then((res) => {
        console.log('查看购物车列表:', res)
        if (res.length > 0) {
          res.map((item) => {
            item.proFreightPrice = 0 //运费
            item.proAmount = JLTools.accMul(item.purchaseNum, item.spec.amount) //商品总价
            item.proChoosed = false //商品勾选标记
          })
        }
        data.productList = res.length > 0 ? res : []
        userStore.updataCartList(data.productList.length)
        recountQuantityAndAmount()
        nextTick(() => {
          data.loading = false
        })
      })
      .catch((err) => {
        console.log(err)
        data.loading = false
      })
  }

  // 全选/取消全选[暂时弃用]
  const updateCheckAllPro = (type) => {
    //修改每个商品的选择状态
    switch (type) {
      case 'delete':
        if (data.checkAll) {
          return proxy.$toast('Please exit the list and select all', { type: 'warning' })
        }
        console.log('全选全部删除')
        data.checkDeleteAll = !data.checkDeleteAll
        for (let item of data.productList) {
          item.proChoosed = data.checkDeleteAll ? true : false
          // if (item.spec.product.executed.toString() === 'true') {
          //   item.proChoosed = data.checkDeleteAll ? true : false
          // }
        }
        break
      default:
        if (data.checkDeleteAll) {
          return proxy.$toast('Please exit the delete all operation', { type: 'warning' })
        }
        data.checkAll = !data.checkAll
        for (let item of data.productList) {
          if (item.spec.product.executed.toString() === 'true' && item.spec.stockCount >= item.purchaseNum) {
            item.proChoosed = data.checkAll ? true : false
          }
        }
        recountQuantityAndAmount()
        break
    }
  }

  // 批量删除选择商品
  const deleteChoosePro = () => {
    let ids = []
    for (let item of data.productList) {
      if (item.proChoosed) {
        ids.push(item.id)
      }
    }

    if (!JLTools.isNull(ids)) {
      proxy.$Message.confirm({
        title: proxy.$t('删除提示!'),
        msg: proxy.$t('确认删除选中商品？'),
        okText: 'Remove',
        onOk: () => {
          data.loading = true
          api.product
            .cartDeletionAll(ids)
            .then((res) => {
              queryCartPro()
            })
            .finally((err) => {
              data.loading = false
            })
        }
      })
    } else {
      proxy.$Message.confirm({
        title: proxy.$t('提示!'),
        msg: proxy.$t('Please select the product(s) first!')
      })
    }
  }

  // 删除
  const deletePro = (item, index) => {
    proxy.$Message.confirm({
      title: proxy.$t('Remove from cart!'),
      msg: proxy.$t('商品将从购物车中删除'),
      okText: 'Remove',
      onOk: () => {
        data.loading = true
        api.product
          .cartDeletion(item.id)
          .then((res) => {
            data.productList.splice(index, 1)
            recountQuantityAndAmount()
            if (!data.productList.length) {
              queryCartPro()
            }
          })
          .finally((err) => {
            data.loading = false
          })
      }
    })
  }

  // 界面重新结算数量和价格
  const recountQuantityAndAmount = () => {
    data.freightMoney = 0
    data.totalMoney = 0
    data.quantityNum = 0
    data.checkList = data.productList.filter((item) => item.proChoosed)
    console.log(`选择列表`, data.checkList)
    for (const item of data.checkList) {
      data.freightMoney += item.proFreightPrice
      data.totalMoney += item.proAmount
      data.quantityNum += item.purchaseNum
    }
  }

  // 单独商品选择
  const choosePro = (pro, i) => {
    if (pro.spec.stockCount < pro.purchaseNum && !data.checkDeleteAll) {
      return proxy.$toast('inventory shortage')
    }
    if (pro.spec.product.executed.toString() !== 'true') return
    data.productList.forEach((item, index) => {
      item.proChoosed = index === i ? !pro.proChoosed : data.all ? item.proChoosed : false
    })
    recountQuantityAndAmount()
  }

  //修改购物车数量
  const updateProNum = (index, num) => {
    let proNum = num ? JLTools.accAdd(data.productList[index].purchaseNum, num) : data.productList[index].purchaseNum
    console.log(proNum, 'proNum')
    if (proNum < 1) {
      proNum = 1
    } else if (proNum > data.productList[index].spec.stockCount) {
      // 超出最大购买数量
      console.log('超出数量')
      proxy.$toast('This product has reached the maximum purchaseable quantity!', { type: 'warning' })
      // proNum = data.productList[index].spec.stockCount
      return
    }
    // 进行深拷贝
    var copiedProduct = JSON.parse(JSON.stringify(data.productList[index]))
    copiedProduct.purchaseNum = proNum
    // 修改购物车数量
    api.product.putCartSpecs(copiedProduct).then((res) => {
      console.log(res, 'res')
      data.productList[index].purchaseNum = res.data.purchaseNum
      if (res.status)
        data.productList.map((item) => {
          item.proFreightPrice = 0 //运费
          item.proAmount = JLTools.accMul(item.purchaseNum, item.spec.amount) //商品总价
        })
      recountQuantityAndAmount()
    })
  }

  //input框直接修改商品数量
  const changeProNum = (index) => {
    data.productList[index].purchaseNum = data.productList[index].purchaseNum
      .toString()
      .trim()
      .replace(/[^0-9]/g, '') // 清除非数字字符
    if (data.productList[index].purchaseNum > data.productList[index].spec.stockCount) {
      proxy.$toast(`This product can choose up to ${data.productList[index].spec.stockCount} pieces`)
      data.productList[index].purchaseNum = data.productList[index].spec.stockCount
    } else if (data.productList[index].purchaseNum > 10000) {
      proxy.$toast(`This product can choose up to 10000 pieces`)
      data.productList[index].purchaseNum = 10000
    }
    // console.log('changeProNum', data.productList[index].purchaseNum)
    debouncedUpdateProNum(index)
  }

  const debouncedUpdateProNum = debounce(updateProNum, 500)

  // 点击查看商品详情
  const toProductDetail = (pro) => {
    if (pro.executed.toString() === 'false') return
    const { href } = proxy.$router.resolve({
      name: 'productMain',
      query: {
        id: pro.id
      }
    })
    window.open(href, '_blank')
  }

  //去结算
  const buy = () => {   
    if (data.checkList.length < 1) {
      let mes = prxoy.$t('Please select the product first!')
      proxy.$toast(mes)
      return
    }
    const queryList = data.checkList.map((item) => ({ specId: item.specId, count: item.purchaseNum }))
    proxy.$router.push({
      name: 'SETTLEMENT',
      query: {
        List: JSON.stringify(queryList),
        cartBJ: 1
      }
    })
  }

  const init = () => {
    queryCartPro()
  }

  onMounted(() => {
    const query = useRoute().query
    data.all = query && query.all ? query.all : data.all
    // init()
  })

  defineExpose({
    queryCartPro
  })
</script>

<template>
  <div class="page-content" v-loading="data.loading">
    <div v-if="!data.loading && data.productList.length" class="content">
      <!-- @click="cancelDeleteChoosePro()" -->
      <div class="cart_header">{{ $t('Shopping Cart') }} ({{ data.productList.length }})</div>
      <div class="cart_table" v-loading="data.loading">
        <div class="cart_table_header rowCC">
          <div class="cart_td select_all rowCC">
            <div v-if="data.all" class="checkbox" :class="{ xuan: data.checkAll }" @click="updateCheckAllPro('checkbox')">
              <img src="~img/login_dui.svg" />
            </div>
          </div>
          <div class="cart_td commodity">{{ $t('Product information') }}</div>
          <div class="cart_td specifications rowCC">{{ $t('Specifications') }}</div>
          <div class="cart_td cart_price rowCC">{{ $t('Unit Price') }}</div>
          <div class="cart_td quantity rowCC">{{ $t('Quantity') }}</div>
          <div class="cart_td freight rowCC" v-if="0">{{ $t('Service Charge') }}</div>
          <div class="cart_td total rowCC">{{ $t('Total') }}</div>
          <div class="cart_td operation rowCC">{{ $t('Operation') }}</div>
        </div>
        <div class="cart_table_body columnCC">
          <div v-for="(pro, index) in data.productList" v-bind:key="index">
            <div
              class="cart_list rowSC"
              :class="{
                xuan: pro.proChoosed,
                disabled: pro.spec.product.executed.toString() === 'false' || pro.spec.stockCount === 0
              }"
            >
              <div class="cart_td select_all rowCC" @click="choosePro(pro, index)">
                <div class="checkbox">
                  <img src="~img/login_dui.svg" />
                </div>
              </div>
              <div class="cart_pro rowSC">
                <div class="pro_img">
                  <JLImg v-if="pro.spec.coverImg" :src="pro.spec.coverImg" @click="toProductDetail(pro.spec.product)" />
                  <JLImg v-else :src="pro.spec.product.coverImg" @click="toProductDetail(pro.spec.product)" />
                  <div class="span" v-if="pro.spec.product.executed.toString() === 'false'">
                    {{ $t('已下架') }}
                  </div>
                  <div class="span" v-else-if="pro.spec.stockCount === 0">
                    {{ $t('outOfStock') }}
                  </div>
                </div>
                <div class="pro_info columnAS">
                  <div class="pro_name" @click="toProductDetail(pro.spec.product)">
                    <hypertext :text="pro.spec.product.name"></hypertext>
                  </div>
                  <div class="pro_name">
                    <hypertext :text="pro.spec.product.supplier" :line="2"></hypertext>
                  </div>
                  <div class="pro_fuwu">
                    <img src="https://obs.pricoo.pk/7d89a282.png" title="Cash on Delivery Available" />
                    <img src="https://obs.pricoo.pk/c60f62a5.png" title="Easy Return & Refund Policy(7 to 14 days) in case of damage unit" />
                    <img src="https://obs.pricoo.pk/da69dffc.png" title="Original Product Warranty" />
                  </div>
                </div>
              </div>
              <div class="pro_shuxing rowCC">
                <div class="pro_shuxing_item" v-if="pro.spec.name">
                  {{ pro.spec.name }}
                </div>
              </div>
              <div class="cart_danjia rowCC">
                <div class="cart_danjia_01 rowCC">
                  {{ JLTools.forPrice(pro.spec.amount) }}
                </div>
                <div class="cart_danjia_02 rowCC" v-if="pro.spec.product.lineAmount && pro.spec.product.lineAmount > pro.spec.amount">
                  {{ JLTools.forPrice(pro.spec.product.lineAmount) }}
                </div>
              </div>
              <div class="cart_quantity rowCC">
                <div class="cart_quantity_item rowCC">
                  <img src="~img/jianhao.svg" :class="{ disable: pro.purchaseNum === 1 }" @click="updateProNum(index, -1)" />
                  <!-- 购买数量 -->
                  <input
                    type="number"
                    v-model="pro.purchaseNum"
                    @input="changeProNum(index)"
                    :max="pro.spec.stockCount"
                    :style="{ background: pro.purchaseNum >= pro.spec.stockCount ? '#f5f5f5' : '' }"
                    :disabled="pro.purchaseNum >= pro.spec.stockCount"
                  />
                  <img
                    src="~img/jiahao.svg"
                    :class="{
                      disable: pro.spec.stockCount <= pro.purchaseNum
                    }"
                    @click="updateProNum(index, 1)"
                  />
                </div>
              </div>
              <!-- 运费 -->
              <div class="cart_service_charge rowCC" v-if="0">
                <div class="cart_service_charge01">{{ $t('Shipping') }}:+{{ JLTools.forPrice(pro.proFreightPrice) }}</div>
              </div>

              <!-- 商品总价 总价减去运费购物车暂无运费体系 -->
              <div class="cart_total rowCC">
                {{ JLTools.forPrice(pro.proAmount) }}
              </div>

              <div class="cart_operation rowCC">
                <div class="cart_operation_item" @click="deletePro(pro, index)">
                  <img class="no1" title="delete" src="~img/cart_shanchu_01.svg" />
                  <img class="no2" title="delete" src="~img/cart_shanchu_02.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="cart_footer rowBC">
        <div class="cart_td">
          <div v-if="data.all" class="rowSC">
            <div class="checkbox" :class="{ xuan: data.checkDeleteAll }" @click="updateCheckAllPro('delete')">
              <img src="~img/login_dui.svg" />
            </div>
            <div class="btn ml10" @click="deleteChoosePro()">{{ $t('Delete') }}</div>
          </div>
        </div>
        <span></span>
        <div class="cart_footer_right">
          <div class="heji">
            <span>Total items:</span><span>{{ data.quantityNum }}</span>
          </div>
          <!-- <div class="heji">
            <span>{{ $t('Shipping') }}:</span><span>+{{ JLTools.forPrice(data.freightMoney) }}</span>
          </div> -->
          <div class="heji">
            <span>{{ $t('Total') }}:</span><b>{{ JLTools.forPrice(data.totalMoney) }}</b>
          </div>
          <button class="btn-primary" @click="buy()" :disabled="!data.checkList.length">
            {{ $t('Check Out') }} <span v-if="data.checkList.length">({{ data.checkList.length }})</span>
          </button>
        </div>
      </div>
    </div>
    <noCartList v-else-if="!data.loading"></noCartList>
  </div>
</template>

<style lang="less" scoped>
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  /* 兼容火狐浏览器 */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .page-content {
    position: relative;
    width: 100%;
    height: auto;
    min-height: calc(100vh - 155px - 170px);
    margin: 0 auto;
  }
  .content {
    position: relative;
    min-height: 400px;
    margin: 0 auto;
    width: var(--minWidth);
    margin-bottom: 20px;
  }
  .btn-primary {
    border-radius: 50px;
  }

  .checkbox {
    float: left;
    width: 14px;
    height: 14px;
    border: 1px solid #aaa;
    cursor: pointer;
  }

  .cart_header {
    float: left;
    width: 100%;
    font-size: 18px;
    margin: 20px 0;
  }
  .cart_table {
    width: 100%;
    max-height: 800px;
    overflow: auto;
    background: #fff;
  }
  .cart_table_header {
    width: 100%;
    border-bottom: 1px solid #eee;
    font-weight: bold;
    .cart_td {
      height: 50px;
      line-height: 50px;
      font-size: 14px;
    }
    .commodity {
      width: 400px;
    }
    .specifications {
      width: 240px;
    }
    .cart_price {
      width: 100px;
    }
    .quantity {
      width: 120px;
    }
    .freight {
      width: 130px;
    }
    .total {
      width: 100px;
    }
    .operation {
      width: 100px;
    }
  }

  .select_all {
    width: 50px;
  }

  .cart_table_body {
    float: left;
    width: 100%;
  }

  .cart_table_body .cart_list {
    float: left;
    border-bottom: 1px solid #f5f5f5;
    width: 100%;
    padding: 20px 0;
  }
  .cart_table_body .cart_list .cart_pro {
    width: 400px;
    .pro_img {
      flex: 0 0 120px;
      height: 120px;
      cursor: pointer;
    }
    .pro_info {
      height: 120px;
      padding: 10px 15px;
      .pro-supplier {
        max-width: 280px;
        margin-top: 5px;
        color: var(--btnBgColor);
      }
      .pro_name {
        width: 100%;
        max-width: 360px;
        font-size: 14px;
        cursor: pointer;
        word-break: break-all;
        line-height: 16px;
      }
      .pro_fuwu {
        img {
          width: 20px;
          height: 20px;
          margin: 5px 10px 0 0;
        }
      }
    }
  }
  .cart_table_body .cart_list .pro_shuxing {
    position: relative;
    width: 240px;
    height: 120px;
    padding: 0 10px 0;
    border: 1px dashed #fff;
    position: relative;
    font-size: 16px;
    .modify {
      position: absolute;
      bottom: 0px;
      right: 0px;
      background: var(--btnBgColor);
      display: none;
      padding: 2px 5px;
      color: #fff;
    }
    .pro_shuxing_item {
      width: 100%;
      text-align: center;
      word-wrap: break-word;
      span {
        color: #999;
      }
    }
    .modify {
      display: block;
    }
  }

  .cart_table_body .cart_list .cart_danjia {
    width: 100px;
    height: 120px;
    font-size: 16px;
  }
  .cart_table_body .cart_list .cart_danjia .cart_danjia_02 {
    width: 100%;
    margin-top: 6px;
    color: #999;
    text-decoration: line-through;
  }
  .cart_quantity {
    width: 120px;
    height: 120px;
  }
  .cart_quantity_item {
    float: left;
    background: #fff;
  }
  .cart_quantity_item img {
    float: left;
    padding: 5px;
    width: 26px;
    height: 26px;
    text-align: center;
    border: 1px solid #ccc !important;
  }
  .cart_quantity_item img:hover {
    cursor: pointer;
    background: #eee;
  }
  .cart_quantity_item img.disable {
    cursor: not-allowed;
    background: #f5f5f5;
  }
  .cart_quantity_item input {
    float: left;
    text-align: center;
    width: 40px;
    height: 26px;
    border: 1px solid #ccc;
    border-left: 0px;
    border-right: 0px;
  }
  .cart_table_body .cart_list .cart_service_charge {
    float: left;
    width: 130px;
    height: 120px;
  }
  .cart_table_body .cart_list .cart_service_charge .cart_service_charge01 {
    line-height: 20px;
  }
  .cart_table_body .cart_list .cart_service_charge .cart_service_coupon {
    float: left;
    position: relative;
    height: 25px;
    text-align: center;
    margin: 10px 0 5px 0;
    line-height: 23px;
    border-radius: 5px;
    border: 1px solid var(--btnBgColor);
  }
  .cart_table_body .cart_list .cart_service_charge .cart_service_coupon img {
    float: left;
    width: 17px;
    height: 17px;
    margin: 3px 5px 3px 8px;
  }
  .cart_table_body .cart_list .cart_service_charge .cart_service_coupon span {
    float: left;
    margin-right: 8px;
    color: var(--btnBgColor);
  }
  .cart_table_body .cart_list .cart_service_charge .cart_service_coupon:hover {
    cursor: pointer;
    background: var(--btnBgColor);
  }

  .cart_service_coupon .cart_coupon_main {
    position: absolute;
    display: none;
    width: 300px;
    min-height: 100px;
    padding: 15px;
    background: #fff;
    z-index: 1;
    top: 30px;
    left: 0px;
    box-shadow: 0 0 15px #ccc;
    border: 1px solid #ccc;
  }

  .cart_service_coupon .cart_coupon_main:before {
    position: absolute;
    top: -7px;
    left: 16px;
    display: inline-block !important;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
  }
  .cart_service_coupon .cart_coupon_main:after {
    position: absolute;
    top: -6px;
    left: 17px;
    display: inline-block !important;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    content: '';
  }

  .cart_service_coupon .cart_coupon_main .item {
    float: left;
    width: 100%;
    line-height: 16px;
    text-align: left;
    margin-bottom: 10px;
    padding: 5px;
  }
  .cart_service_coupon .cart_coupon_main .item:hover {
    cursor: pointer;
    background: #f5f5f5;
  }
  .cart_service_coupon .cart_coupon_main .item.xuan .checkbox {
    background: var(--btnBgColor);
    border: 1px solid var(--btnBgColor);
  }
  .cart_service_coupon .cart_coupon_main .item.xuan .checkbox img {
    display: block;
  }
  .cart_service_coupon .cart_coupon_main .item.xuan {
    color: var(--btnBgColor);
  }

  .cart_table_body .cart_list .cart_total {
    float: left;
    width: 100px;
    height: 120px;
    font-size: 16px;
    color: var(--PicColor);
  }
  .cart_table_body .cart_list .cart_operation {
    float: left;
    width: 100px;
    height: 120px;
  }
  .cart_table_body .cart_list .cart_operation .cart_operation_item {
    width: 22px;
    height: 22px;
  }
  .cart_table_body .cart_list .cart_operation .cart_operation_item .no2 {
    display: none;
  }
  .cart_table_body .cart_list .cart_operation .cart_operation_item img {
    float: left;
    width: 100%;
    height: 100%;
  }
  .cart_table_body .cart_list .cart_operation .cart_operation_item:hover {
    cursor: pointer;
  }
  .cart_table_body .cart_list .cart_operation .cart_operation_item:hover .no2 {
    display: block;
  }
  .cart_table_body .cart_list .cart_operation .cart_operation_item:hover .no1 {
    display: none;
  }

  .cart_table_body .cart_list.xuan {
    background: #e9e5e5;
  }
  .cart_table_body .cart_list.xuan .pro_shuxing {
    border: 1px solid #e9e5e5;
  }
  .cart_table_body .cart_list.xuan .checkbox {
    background: var(--btnBgColor);
    border: 1px solid var(--btnBgColor);
  }
  .cart_table_body .cart_list.xuan .checkbox img {
    display: block;
  }

  .cart_table_body .cart_list:last-child {
    border-bottom: 0px;
  }

  .cart_table_body .cart_list.disabled {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
  }
  .cart_table_body .cart_list.disabled .checkbox:hover {
    cursor: no-drop !important;
  }
  .cart_table_body .cart_list.disabled .cart_pro .pro_img {
    position: relative;
  }
  .cart_table_body .cart_list.disabled .cart_pro .pro_img .span {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 20px;
    text-align: center;
    line-height: 20px;
    background: rgba(0, 0, 0, 0.5);
    bottom: 0px;
    color: #fff;
  }

  .cart_footer {
    width: 100%;
    height: 60px;
    background: #fff;
    margin-top: 20px;
    font-size: 13px;
    .cart_td {
      margin-left: 24px;
    }
    .select_all {
      margin-top: 5px;
    }
  }
  .cart_footer .btn:hover {
    cursor: pointer;
    color: var(--btnBgColor);
    text-decoration: underline;
  }
  .cart_footer_right {
    float: right;
  }
  .cart_footer_right .heji {
    float: left;
    line-height: 60px;
    margin-right: 20px;
  }
  .cart_footer_right .heji span {
    float: left;
    margin-right: 5px;
  }
  .cart_footer_right .heji b {
    float: left;
    font-size: 16px;
    color: var(--PicColor);
  }
  .cart_footer_right button {
    float: left;
    width: 150px;
    color: #fff;
    font-size: 16px;
    height: 40px;
    background: var(--btnBgColor);
    margin: 10px;
    &[disabled] {
      opacity: 0.7;
    }
  }
  .cart_footer_right button:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  .push_pro_item {
    float: left;
    width: 191.5px;
    height: 290px;
    background: #fff;
    margin: 0 0 10px 10px;
  }
  .push_pro_item .pro_img {
    float: left;
    width: 191.5px;
    height: 191.5px;
  }
  .push_pro_item .pro_name {
    float: left;
    width: 200px;
    padding: 0 10px;
    height: 40px;
  }
  .push_pro_item .pro_pic {
    float: left;
    width: 100%;
    height: 20px;
    line-height: 20px;
    padding: 0 10px;
    margin-top: 5px;
    font-size: 18px;
    color: var(--PicColor);
  }
  .push_pro_item .pro_pic_x {
    float: left;
    width: 100%;
    height: 20px;
    line-height: 20px;
    padding: 0 10px;
    color: #999;
    text-decoration: line-through;
  }
  .push_pro_item:nth-child(6n + 1) {
    margin-left: 0px;
  }
  .push_pro_item:hover {
    cursor: pointer;
    box-shadow: 0 0 15px #c1c1c1;
    transition: all 0.3s;
  }

  .index_floor {
    float: left;
    width: 100%;
  }
  .floor_header {
    float: left;
    width: 100%;
    height: 30px;
    line-height: 30px;
    margin: 20px 0;
  }
  .floor_header .left_img {
    float: left;
    width: 20px;
    height: 20px;
    margin: 5px 5px 0 0;
  }
  .floor_header b {
    font-size: 18px;
    font-weight: 400;
  }
  .floor_header .more {
    float: right;
  }
  .floor_header .more span {
    float: left;
    font-size: 14px;
    color: #999;
    line-height: 30px;
  }
  .floor_header .more .right_img {
    float: left;
    width: 16px;
    height: 16px;
    transform: rotate(270deg);
    margin: 8px 0 0 2px;
  }
  .floor_header .more .no2 {
    display: none;
  }
  .floor_header .more:hover {
    cursor: pointer;
  }
  .floor_header .more:hover span {
    color: var(--btnBgColor);
  }
  .floor_header .more:hover .no1 {
    display: none;
  }
  .floor_header .more:hover .no2 {
    display: block;
  }

  .fenye_right_none {
    color: #ccc;
  }
  .fenye_right_none:hover {
    background: #fff !important;
    cursor: no-drop !important;
  }

  .checkbox img {
    float: left;
    width: 100% !important;
    height: 100% !important;
    display: none;
    margin: 0px !important;
  }
  .checkbox.xuan {
    background: var(--btnBgColor);
    border: 1px solid var(--btnBgColor);
  }
  .checkbox.xuan img {
    display: block;
  }
</style>

<template>
  <div class="index_floor">
    <div class="floor_header">
      <b>{{ $t('order.MostPopular') }}</b>
    </div>
    <div class="floor_body">
      <div class="push_pro_item" v-for="(item, index) of pickProductList" :key="index" @click="toDetails(item.id)">
        <div class="pro_img">
          <JLImg :src="item.coverImg" />
        </div>
        <div class="pro_name text_hide2">{{ item.name }}</div>
        <div class="pro_pic">{{ JLTools.forPrice(item.amount) }}</div>
        <div class="pro_pic_x" v-if="item.lineAmount && item.lineAmount > item.amount && item.lineAmount >= 1">{{ JLTools.forPrice(item.lineAmount) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  
  export default {
    name: 'PickProduct',
    data() {
      return {
        pickProductList: [] //推荐商品列表
      }
    },
    mounted() {
      this.getPickProduct()
    },
    methods: {
      //获取推荐商品
      getPickProduct() {
        let data = {
          'executed.equals': 'true',
          size: 12,
          page: 0,
          sort: 'saleCount,desc',
          'productType.equals': 'GENERAL'
        }
        api.product.getProducts(data).then((res) => {
          this.pickProductList = res.data
          // console.log('数量',res)
        })
      },
      //跳转商品详情
      toDetails(id) {
        const { href } = this.$router.resolve({
          name: 'productMain',
          query: {
            id: id
          }
        })
        window.open(href, '_blank')
      }
    }
  }
</script>

<style scoped>
  .content {
    width: var(--minWidth);
    margin: 0 auto;
  }
  .index_floor {
    width: var(--minWidth);
    margin: 0 auto;
    padding-top: 20px;
  }
  .floor_header {
    width: 100%;
    height: 30px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .floor_header .left_img {
    width: 20px;
    height: 20px;
    margin: 5px 5px 0 0;
  }
  .floor_header b {
    font-size: 18px;
    font-weight: 400;
  }
  .floor_header .more {
    float: right;
  }
  .floor_header .more span {
    font-size: 14px;
    color: #999;
    line-height: 30px;
  }
  .floor_header .more .right_img {
    width: 16px;
    height: 16px;
    transform: rotate(270deg);
    margin: 8px 0 0 2px;
  }
  .floor_header .more .no2 {
    display: none;
  }
  .floor_header .more:hover {
    cursor: pointer;
  }
  .floor_header .more:hover span {
    color: var(--btnBgColor);
  }
  .floor_header .more:hover .no1 {
    display: none;
  }
  .floor_header .more:hover .no2 {
    display: block;
  }

  .floor_body {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    gap: 20px 10px;
    justify-content: space-between;
    align-content: center;
    justify-items: center;
    align-items: center;
  }
  .push_pro_item {
    width: 191.5px;
    height: 290px;
    background: #fff;
    margin: 0 0 10px 10px;
    border-radius: 8px;
    overflow: hidden;
  }
  .push_pro_item .pro_img {
    width: 191.5px;
    height: 191.5px;
  }
  .push_pro_item .pro_img img {
    width: 191.5px;
    height: 191.5px;
  }
  .push_pro_item .pro_name {
    float: left;
    width: 200px;
    padding: 0 10px;
    height: 40px;
    line-height: 20px;
  }
  .push_pro_item .pro_pic {
    float: left;
    width: 100%;
    height: 20px;
    line-height: 20px;
    padding: 0 10px;
    margin-top: 5px;
    font-size: 18px;
    color: var(--PicColor);
  }
  .push_pro_item .pro_pic_x {
    float: left;
    width: 100%;
    height: 20px;
    line-height: 20px;
    padding: 0 10px;
    color: #999;
    text-decoration: line-through;
  }
  .push_pro_item:nth-child(6n + 1) {
    margin-left: 0px;
  }
  .push_pro_item:hover {
    cursor: pointer;
    box-shadow: 0 0 15px #c1c1c1;
    transition: all 0.3s;
  }
</style>
